<template>
  <div class="all">
    <van-nav-bar
      :title="detail.company"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    >
    </van-nav-bar>
    <swiper class="swiper" :options="swiperOption">
      <swiper-slide>
        <img :src="image" alt="" />
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
    <div class="baseinfo">
      <p class="company">{{ detail.company }}招聘信息</p>
      <p class="work_money">
        {{ detail.max_salary }}元/{{detail.salary_type_human}}
      </p>
      <p class="deadline">报名截止日期: {{ detail.deadline }}</p>
      <van-divider />
      <div class="form-button">
        <van-button size="small" type="info" icon="add" @click="showForm"
          >立即报名</van-button
        >
      </div>
    </div>

    <div class="otherinfo">
      <van-tabs v-model="active">
        <van-tab title="工资说明">
          <van-list>
            <van-cell>
              <p class="title">工资说明</p>
            </van-cell>
            <van-cell>
              工资待遇： {{ detail.max_salary }}元/{{
                detail.salary_type_human
              }}
            </van-cell>
            <van-cell>
              <p class="issue">上班有什么法律保障？</p>
              合同说明： 与企业指定的人力资源公司签订
            </van-cell>
            <van-cell>
              <span v-if="detail.insurance == 1">保险： 购买保险</span>
              <span v-if="detail.insurance == 0">保险： 无购买保险</span>
            </van-cell>
            <van-cell>
              试用期： <span v-if="detail.probation == 0">无试用期</span>
              <span v-else>试用期为{{ detail.probation }}个月</span>
            </van-cell>
          </van-list>
        </van-tab>
        <van-tab title="录用条件">
          <van-list>
            <van-cell>
              <p class="issue">面试有什么要求？</p>
              年龄： {{ detail.min_age }}-{{ detail.max_age }}岁
            </van-cell>
            <van-cell> 学历要求： {{ edu }} </van-cell>
            <van-cell>
              身份证件：<span v-if="detail.card == 0">不需要身份证</span>
              <span v-else>需要身份证</span>
            </van-cell>
            <van-cell>
              岗位要求：<span v-if="detail.other == null">暂无要求</span>
              <span v-else>{{ detail.other }}</span>
            </van-cell>
            <van-cell>
              <p class="issue">干什么活？</p>
              工作时间：
              <span v-if="detail.work_time == null">暂无时间安排</span>
              <span v-else>{{ detail.work_time }}</span>
            </van-cell>
            <van-cell>
              工作方式：
              <span v-if="detail.work_type == null">暂无信息</span>
              <span v-else>{{ detail.work_type }}</span>
            </van-cell>
            <van-cell>
              工作环境：
              <span v-if="detail.work_environment == null">暂无信息</span>
              <span v-else>{{ detail.work_environment }}</span>
            </van-cell>
            <van-cell>
              <p class="issue">其他信息</p>
              体检说明：
              <span v-if="detail.physical == null">暂无信息</span>
              <span v-else>{{ detail.physical }}</span>
            </van-cell>
          </van-list>
        </van-tab>
        <van-tab title="企业介绍">
          <van-list>
            <van-cell>
              <p class="title">企业介绍</p>
              <br />
              <span style="font-size: 14px" v-if="detail.intro == null">
                <div class="warm">
                  <van-icon name="description" size="60" /><br />
                  <p>暂无介绍</p>
                </div>
              </span>
              <span v-else>{{ detail.intro }}</span>
            </van-cell>
          </van-list>
        </van-tab>
        <van-tab title="温馨提示">
          <van-list>
            <van-cell>
              <p class="hint">温馨提示</p>
              <br />
              <span
                style="font-size: 14px; text-align: center"
                v-if="detail.warm_prompt == null"
              >
                <div class="warm">
                  <van-icon name="description" size="60" /><br />
                  <p>暂无提示</p>
                </div>
              </span>
              <span v-else>{{ detail.warm_prompt }}</span>
            </van-cell>
          </van-list>
        </van-tab>
      </van-tabs>
    </div>
    <tab-bar></tab-bar>
    <van-popup v-model="show" :style="{ height: '46%' }" position="bottom">
      <p
        style="
          font-size: 18px;
          text-align: center;
          margin: 10px auto;
          color: #666;
          font-weight: 30;
        "
      >
        我要应聘
      </p>
      <van-form @submit="onSubmit">
        <van-field
          v-model="name"
          rows="1"
          autosize
          label="姓名"
          type="textarea"
          class="field"
          input-align="right"
          placeholder="应聘者姓名"
        />
        <van-field
          v-model="phone"
          rows="1"
          autosize
          label="手机号码"
          type="textarea"
          class="field"
          input-align="right"
          placeholder="应聘者手机号码"
        />
        <van-field
          v-model="idCard"
          rows="1"
          autosize
          label="身份证号"
          type="textarea"
          class="field"
          input-align="right"
          placeholder="应聘者身份证号码"
        />
        <div style="margin: 16px">
          <van-button round block type="info" native-type="submit">
            提交
          </van-button>
        </div>
      </van-form>
    </van-popup>
  </div>
</template>


<script>
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import { factoryDetail } from "network/others";
import {
  Divider,
  Button,
  Tab,
  Tabs,
  List,
  Cell,
  Icon,
  Popup,
  Form,
  Field,
  Toast,
  NavBar,
} from "vant";
import { apply } from "network/others";
import TabBar from "common/navbar/TabBar";
export default {
  name: "workDetail",
  components: {
    TabBar,
    swiper,
    swiperSlide,
    [Divider.name]: Divider,
    [Button.name]: Button,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [List.name]: List,
    [Cell.name]: Cell,
    [Icon.name]: Icon,
    [Popup.name]: Popup,
    [Form.name]: Form,
    [Field.name]: Field,
    [Toast.name]: Toast,
    [NavBar.name]: NavBar,
  },
  data() {
    return {
      image: "",
      swiperOption: {
        autoplay: {
          delay: 3000,
        },
        pagination: {
          el: ".swiper-pagination",
        },
      },
      detail: "",
      active: 0,
      edu: "",
      show: false,
      name: "",
      phone: "",
      idCard: "",
      company: "",
    };
  },
  mounted() {
    this.getFactoryDetail();
  },
  methods: {
    getFactoryDetail() {
      factoryDetail(this.$route.query.id).then((res) => {
        this.detail = res.data;
        this.company = this.detail.company;
        this.image = res.data.image;
        this.edu = this.detail.edu.name;
      });
    },
    showForm() {
      this.show = true;
    },
    onClickLeft() {
      this.$router.push({
        path: "/workList",
      });
    },
    checkPhone(phone) {
      // var phone = document.getElementById('phone').value;
      if (!/^1[3456789]\d{9}$/.test(phone)) {
        return false;
      } else {
        return true;
      }
    },
    onSubmit(values) {
      if (!this.name) {
        Toast.fail("应聘者姓名不能为空");
        return;
      }
      if (!this.phone) {
        Toast.fail("应聘者手机号码不能为空");
        return;
      }

      if (!this.checkPhone(this.phone)) {
        Toast.fail("手机号码格式有误");
        return;
      }

      if (!this.idCard) {
        Toast.fail("应聘者身份证号码不能为空");
        return;
      }
      if (!this.company) {
        Toast.fail("应聘工厂不能为空");
        return;
      }
      apply(this.name, this.phone, this.idCard, this.company).then((res) => {
        if (res == "填写成功") {
          Toast.success("提交成功,3秒后自动跳转！");
          this.show = false;
          setTimeout(() => {
            this.$router.push({
              path: "/workList",
              query: { title: "我要应聘" },
            });
          }, 2000);
        }
      });
    },
  },
};
</script>

<style>
.all {
  font-family: Helvetica, "Hiragino Sans GB", "Microsoft Yahei", "微软雅黑",
    Arial, sans-serif;
}
.swiper-container {
  width: 100%;
  height: 200px;
}
.swiper-container img {
  width: 100%;
  height: 100%;
}
.baseinfo {
  padding: 26px 8px 0;
  background-color: #fff;
  margin-bottom: 10px;
}
.baseinfo .company {
  font-size: 28px;
  color: #323232;
  line-height: 44px;
  height: 50px;
}
.baseinfo .work_money {
  color: rgb(230, 160, 60);
  font-size: 14px;
  height: 28px;
  line-height: 28px;
}
.deadline {
  font-size: 14px;
  height: 28px;
  line-height: 28px;
}
.form-button {
  display: block;
  text-align: center;
  padding-bottom: 20px;
}
.otherinfo {
  background-color: #fff;
  margin-bottom: 50px;
}
.title {
  color: #000;
  font-size: 16px;
  font-weight: bold;
}
.hint {
  color: rgb(255, 172, 56);
  font-size: 16px;
  font-weight: bold;
}
.issue {
  color: #000;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  height: 24px;
  line-height: 18px;
}
.warm {
  width: 100%;
  height: 100%;
  display: inline-block;
  text-align: center;
  margin: auto;
  padding-top: 20px;
  padding-bottom: 40px;
  font-size: 16px;
  color: #ccc;
}
.field {
  display: flex;
  align-items: center;
  height: 60px;
}
</style>